/*
	Generated using http://www.responsivegridsystem.com/calculator/
	12 columns (2.8% margin) + 5 columns (2.8% margin)
	.section and .group renamed to .rgs-section and .rgs-group to avoid conflicts with magento classes
    Reusable elements added: .rgs-container, .hidden-xsmall, .hidden-block-small, .hidden-small, .hidden-block-small, .visible-xsmall
        use .rgs-grid--max-[x]-col to make grids collapse to 2 or 3 columns for large or medium sized viewports
            Example: <div class="rgs-container rgs-section rgs-group rgs-grid--max-4-col">...cols...</div>
        use .rgs-grid on a ul element to mimic the magento product grid
            Example: <ul class="rgs-container rgs-section rgs-group rgs-grid rgs-grid--max-4-col">...li cols...</ul>
*/

.rgs-container {
    position: relative;
    max-width: 1260px;
    margin: 0 auto !important;
    padding: 15px !important;
}
.rgs-section {
	clear: both;
	padding: 0px;
	margin: 0px;
}
.col {
	display: block;
	float: left;
	margin: 1% 0 1% 2.8%;
    img {
        max-width: 100%;
        height: auto;
    }
}
.col:first-child { margin-left: 0; }

.rgs-group:before,
.rgs-group:after { content:""; display:table; }
.rgs-group:after { clear:both;}
.rgs-group { zoom:1; /* For IE 6/7 */ }

/*  GRID OF TWELVE  */
.span_12_of_12 {
	width: 100%;
}
.span_11_of_12 {
  	width: 91.43%;
}
.span_10_of_12 {
  	width: 82.86%;
}
.span_9_of_12 {
  	width: 74.3%;
}
.span_8_of_12 {
  	width: 65.73%;
}
.span_7_of_12 {
  	width: 57.16%;
}
.span_6_of_12 {
  	width: 48.6%;
}
.span_5_of_12 {
  	width: 40.03%;
}
.span_4_of_12 {
  	width: 31.46%;
}
.span_3_of_12 {
  	width: 22.9%;
}
.span_2_of_12 {
  	width: 14.33%;
}
.span_1_of_12 {
  	width: 5.766%;
}

/*  GRID OF FIVE  */
.span_5_of_5 {
	width: 100%;
}
.span_4_of_5 {
	width: 79.44%;
}
.span_3_of_5 {
	width: 58.88%;
}
.span_2_of_5 {
	width: 38.32%;
}
.span_1_of_5 {
	width: 17.76%;
}

// start: ---- zelf toegevoegd

.hidden-xsmall, .hidden-block-xsmall, .hidden-small, .hidden-block-small {
	display: none;
}
ul.rgs-grid > li {
    position: relative;
    margin-bottom: 30px;
    .product-info {
        padding: 0 0 50px 0;
        .product-name {
            padding: 0 13px 10px 13px;
            margin: 0;
        }
    }
    .actions {
        position: absolute;
        bottom: 0;
        padding: 0;
        width: 100%;
    }
}
@include bp(min-width, $bp-xsmall+1) {
    /* These styles will only display on viewports larger than or equal to 480px (479px + 1px) */
	.rgs-container {
		padding: 30px !important;
	}
    .hidden-xsmall {
        display: inline;
    }
    .hidden-block-xsmall {
        display: block;
    }
    .visible-xsmall {
        display: none;
    }
}
@include bp(min-width, $bp-small+1) {
    /* These styles will only display on viewports larger than or equal to 600px (599px + 1px) */
    .hidden-small {
        display: inline;
    }
    .hidden-block-small {
        display: block;
    }
}
@include bp(max-width, $bp-xsmall) {
    /* These styles will only display on viewports smaller than or equal to 479px */
    ul.rgs-grid > li {
        .product-info {
            min-height: 0 !important;
            padding: 0 !important;
        }
        .actions {
            position: static !important;
            padding: 10px 0 0 0 !important;
        }
    }
}

/* AUTOMATIC CLEARS (USE ADITIONAL CLASS: .rgs-grid--max-[x]-col) */
.rgs-grid--max-2-col .col:nth-child(odd) {
    clear: left;
    margin-left: 0;
}
.rgs-grid--max-3-col .col:nth-child(3n+4) {
    clear: left;
    margin-left: 0;
}
.rgs-grid--max-4-col .col:nth-child(4n+5) {
    clear: left;
    margin-left: 0;
}
.rgs-grid--max-5-col .col:nth-child(5n+6) {
    clear: left;
    margin-left: 0;
}
.rgs-grid--max-6-col .col:nth-child(6n+7) {
    clear: left;
    margin-left: 0;
}

/* COLLAPSE TO 2 OR 3 COLUMNS WHEN GOING TO LARGE (USE ADDITIONAL CLASS: .bp-large) */
@include bp(max-width, $bp-large) {
    /* These styles will only display on viewports smaller than or equal to 979px */
    .rgs-grid--max-3-col.bp-large .col {
        width: 48.6%;
        &:nth-child(2n+0) {
            clear: none;
            margin-left: 2.8%;
        }
        &:nth-child(odd) {
            clear: left;
            margin-left: 0;
        }
    }
    .rgs-grid--max-4-col.bp-large .col {
        width: 48.6%;
        &:nth-child(even) {
            clear: none;
            margin-left: 2.8%;
        }
        &:nth-child(odd) {
            clear: left;
            margin-left: 0;
        }
    }
    .rgs-grid--max-5-col.bp-large .col {
        width: 31.46%;
        &:nth-child(5n+6) {
            clear: none;
            margin-left: 2.8%;
        }
        &:nth-child(3n+4) {
            clear: left;
            margin-left: 0;
        }
    }
    .rgs-grid--max-6-col.bp-large .col {
        width: 31.46%;
        &:nth-child(6n+7) {
            clear: none;
            margin-left: 2.8%;
        }
        &:nth-child(3n+4) {
            clear: left;
            margin-left: 0;
        }
    }
}
/* COLLAPSE TO 2 OR 3 COLUMNS WHEN GOING TO MEDIUM */
@include bp(max-width, $bp-medium) {
    /* These styles will only display on viewports smaller than or equal to 770px */
    .rgs-grid--max-3-col .col {
        width: 48.6%;
        &:nth-child(2n+0) {
            clear: none;
            margin-left: 2.8%;
        }
        &:nth-child(odd) {
            clear: left;
            margin-left: 0;
        }
    }
    .rgs-grid--max-4-col .col {
        width: 48.6%;
        &:nth-child(even) {
            clear: none;
            margin-left: 2.8%;
        }
        &:nth-child(odd) {
            clear: left;
            margin-left: 0;
        }
    }
    .rgs-grid--max-5-col .col {
        width: 31.46%;
        &:nth-child(5n+6) {
            clear: none;
            margin-left: 2.8%;
        }
        &:nth-child(3n+4) {
            clear: left;
            margin-left: 0;
        }
    }
    .rgs-grid--max-6-col .col {
        width: 31.46%;
        &:nth-child(6n+7) {
            clear: none;
            margin-left: 2.8%;
        }
        &:nth-child(3n+4) {
            clear: left;
            margin-left: 0;
        }
    }
}

// end: ---- zelf toegevoegd

/*  ALWAYS GO FULL WIDTH BELOW 480 PIXELS */
@include bp(max-width, $bp-xsmall) {
	.col {  margin: 1% 0 1% 0%; }
    .span_1_of_12, .span_2_of_12, .span_3_of_12, .span_4_of_12, .span_5_of_12, .span_6_of_12, .span_7_of_12, .span_8_of_12, .span_9_of_12, .span_10_of_12, .span_11_of_12, .span_12_of_12 {
		width: 100% !important;
        margin-left: 0 !important;
	}
    .span_1_of_5, .span_2_of_5, .span_3_of_5, .span_4_of_5, .span_5_of_5 {
        width: 100% !important;
        margin-left: 0 !important;
    }
}