// start: algemeen
p, .std p {
    margin-bottom: 10px;
}
img {
    max-width: 100%;
    height: auto;
}
.f-right {
    float: right;
}
.fluid-width-video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
#contactForm {
    padding-left: 0;
}
label {
    font-family: $f-stack-sans;
}
.buttons-set {
    text-align: left;
    button.button {
        float: none;
        margin-left: 0;
    }
}
.button {
    border-radius: 3px;
}
.button-large {
    font-size: 23px;
    font-weight: 600;
    padding: 14px 15px 12px 15px;
}
.input-text {
    font-size: $f-size-xs;
}
button.button.button3 {
    background-color: #fff;
    border: 2px solid $c-red;
    color: $c-red;
    font-weight: 500;
    &:hover {
        background-color: $c-red;
        border-color: $c-red;
        color: #fff;
    }
}
// end: algemeen

// start: header
#header {
    font-size: $f-size-xxs;
}
.logo {
    width: 50%;
    padding: 15px 10px;
    &:hover {
        opacity: 0.95;
    }
}
.langs-wrapper {
    a {
        display: inline-block;
        width: 24px;
        height: 18px;
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
            border-radius: 1px;
        }
    }
}
.header-language-background {
    /* (desktop view) */
    background-color: #000;
    padding: 5px 0 5px 0;
    text-transform: none;
    a {
        font-size: 13px;
        line-height: 24px;
        font-weight: 600;
    }
    .topnav-links-left {
        float: right;
        margin-right: 15px;
        a {
            margin-left: 10px;
        }
    }
    .store-language-container {
        float: right;
        .langs-wrapper a {
            width: 16px;
            height: 18px;
            vertical-align: middle;
            margin-top: 6px;
        }
    }
    .topnav-links-container {
        float: right;
        margin-left: 5px;
        margin-right: 10px;
        .topnav-links {
            li {
                float: left;
                margin-left: 10px;
            }
        }
    }
}
.page-header-container {
    /* (mobile view) */
    .store-language-container {
        max-width: 50%;
    }
    .topnav-links-container {
        width: 100%;
        clear: both;
        font-size: $f-size-xxs;
        .topnav-links {
            border-top: 1px solid $c-module-border-light;
            padding: 5px 0;
            li {
                width: 50%;
                float: left;
                padding-left: 10px;
                margin-bottom: 5px;
                &:before {
                    content: "•";
                    color: $c-red;
                }
                a {
                    color: darken($c-text, 10%);
                    padding-left: 10px;
                }
            }
        }
    }
}
#headerimage {
    height: 150px;
    background-size: cover;
    background-color: #000;
    background-repeat: no-repeat;
    background-position: 50% 0;
}
.skip-links {
    border-left: 1px solid $c-module-border-light;
}
#search {
    font-family: $f-stack-sans;
}
.breadcrumbs {
    li {
        text-transform: none;
        font-size: 14px;
        font-weight: 600;
    }
    strong {
        color: $c-red;
        font-weight: 600;
    }
}
// end: header

// start: footer
.footer-bg1 {
    background-color: #222;
    color: #666;
    a {
        color: #666;
    }
}
.footer-bg2 {
    background-color: #efefef;
}
.footer-contact {
    .col {
        margin-bottom: 40px;
    }
    a.tel {
        color: $c-text;
        &:hover {
            font-weight: 600;
            text-decoration: none;
        }
    }
    .footer-contact-form {
        p.required {
            font-size: 14px;
            float: none;
            padding-top: 10px;
            margin: 20px 0 0 0;
            border-top: 1px solid $c-module-border;
        }
        label {
            font-weight: 400;
        }
    }
}
.footer-copyright {
    .rgs-container {
        padding: 10px 30px !important;
    }
    font-size: $f-size-xxs;
    a {
        font-weight: 600;
    }
    .footer-copyright-right {
        a {
            &:hover {
                text-decoration: none;
                color: $c-red;
            }
        }
        .icon-play {
            font-size: 24px;
        }
    }
}
// end: footer

// start: product view
.product-view {
    .product-shop {
        .product-name {
            .h1 {
                color: $c-text;
                font-weight: 500;
                font-size: 36px;
            }
        }
    }
    .product-img-box {
        .product-name {
            h1 {
                color: $c-text;
                font-weight: 500;
                font-size: 36px;
            }
        }
    }
    .add-to-cart {
        border: 0;
    }
}
.product-img-box .product-image, .no-touch .product-img-box .product-image:not(.zoom-available):hover {
    border: 0;
}
.product-img-box .product-image {
    img {
        margin: 0;
    }
}
.btn-cart {
    background-color: #fff;
    border: 2px solid $c-red;
    color: $c-red;
    font-weight: 500;
    &:hover {
        background-color: $c-red;
        border-color: $c-red;
        color: #fff;
    }
}
// end: product view

// start: offerte
#pv360-step1-form {
    background-color: $c-module-background;
    padding: 30px;
    margin-bottom: 150px;
    img {
        margin-bottom: 30px;
        max-height: 150px;
    }
    input[type=number] {
        padding: 20px 8px;
    }
    .buttons-set {
        margin-top: 20px;
    }
}
#pv360-step2-form {
    background-color: $c-module-background;
    padding: 30px;
    margin-bottom: 150px;
    input[type=text], input[type=email] {
        padding: 20px 8px;
        border: 0;
        width: 100%;
        max-width: 365px;
    }
    textarea {
        border: 0;
        width: 100%;
        max-width: 365px;
    }
    .note {
        color: $c-text-gray;
        font-size: $f-size-xxs;
        font-weight: 400;
    }
    .buttons-set {
        max-width: 365px;
        .button {
            white-space: initial;
            line-height: 1.2;
        }
        .button-info {
            float: right;
            text-align: center;
            .note {
                color: $c-text;
                font-weight: 600;
                line-height: 1.1;
                margin-top: 10px;
            }
        }
    }
    .total {
        border-top: 1px solid $c-module-border-light;
        font-size: 22px;
        font-weight: 700;
        label {
            font-size: 22px;
            font-weight: 700;
        }
    }
    .offerte-results {
        margin-bottom: 30px;
        font-weight: 600;
        label {
            &:after {
                content: ":";
            }
        }
        .rgs-group > {
            .col:first-child {
                margin-bottom: 0;
            }
            .col:nth-child(2) {
                margin-top: 0;
            }
        }
    }
}
#pv360-brochure-form {
    background-color: $c-module-background;
    padding: 30px;
    margin-bottom: 150px;
    input[type=text], input[type=email] {
        padding: 20px 8px;
        border: 0;
        width: 100%;
        max-width: 365px;
    }
    textarea {
        border: 0;
        width: 100%;
        max-width: 365px;
    }
    .note {
        color: $c-text-gray;
        font-size: $f-size-xxs;
        font-weight: 400;
    }
    .buttons-set {
        max-width: 365px;
        .button {
            white-space: initial;
            line-height: 1.2;
        }
        .button-info {
            float: right;
            text-align: center;
            .note {
                color: $c-text;
                font-weight: 600;
                line-height: 1.1;
                margin-top: 10px;
            }
        }
    }
    .image img {
        max-height: 350px;
        margin: 0 auto 0 auto;
        padding-left: 20px;
    }
}
// end: offerte

// start: checkout/cart/minicart
.header-minicart .block-subtitle {
    color: $c-red;
}
// end: checkout/cart/minicart

@include bp(max-width, $bp-large) {
    /* These styles will only display on viewports smaller than or equal to 979px */

}
@include bp(max-width, $bp-medium) {
    /* These styles will only display on viewports smaller than or equal to 770px */
    #pv360-step2-form {
        > .rgs-section > .col {
            width: 100%;
            margin-left: 0;
        }
    }
}
@include bp(max-width, $bp-small) {
    /* These styles will only display on viewports smaller than or equal to 599px */
    .footer-contact {
        .span_6_of_12 {
            width: 100%;
            margin-left: 0;
            float: none;
            clear: both;
        }
    }
    #pv360-step1-form {
        > .rgs-section > .col {
            width: 100%;
            margin-left: 0;
        }
    }
}
@include bp(max-width, $bp-xsmall) {
    /* These styles will only display on viewports smaller than or equal to 479px */
}
@include bp(min-width, $bp-xsmall+1) {
    /* These styles will only display on viewports larger than or equal to 480px (479px + 1px) */
    .logo {
        padding: 15px 30px;
    }
    .page-header-container .topnav-links-container .topnav-links {
        padding: 5px 20px;
    }
    #pv360-step2-form .offerte-results .rgs-group {
        > .col:first-child {
            margin-bottom: 1%;
        }
        > .col:nth-child(2) {
            margin-top: 1%;
        }
    }
}
@include bp(min-width, $bp-small+1) {
    /* These styles will only display on viewports larger than or equal to 600px (599px + 1px) */
    .logo {
        width: 60%;
    }
}
@include bp(min-width, $bp-medium+1) {
    /* These styles will only display on viewports larger than or equal to 771px (770px + 1px) */
    .logo {
        padding-left: 0;
    }
    #headerimage {
        height: 298px;
    }
    .product-collateral .toggle-tabs {
        background-color: #fff;
        border: 0;
        border-bottom: 1px solid $c-module-border;
        li {
            > span {
                text-transform: none;
            }
            &.current {
                border: 0;
                > span {
                    background-color: $c-module-background-dark;
                    color: #fff;
                }
            }
        }
    }
    #pv360-step1-form {
        img {
            max-height: none;
            margin-left: 40px;
            margin-bottom: 0;
        }
    }
}
@include bp(min-width, $bp-large+1) {
    /* These styles will only display on viewports larger than or equal to 980px (979px + 1px) */
    .logo {
        width: 300px;
    }
    .skip-account, .skip-cart {
        text-transform: none;
    }
}
@include bp(min-width, $bp-xlarge+1) {
    /* These styles will only display on viewports larger than or equal to 1200px (1199px + 1px) */
    #pv360-step2-form {
        .offerte-results {
            label:after {
                content: "";
            }
            .rgs-group > .col {
                /* vervang margin door padding ivm border */
                margin: 0;
                padding: 1% 0 1% 2.8%;
                &:first-child {
                    margin-bottom: 0;
                    padding-bottom: 1%;
                }
                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 1%;
                }
                &:nth-child(2) {
                    margin-top: 0;
                    padding-top: 1%;
                    border-left: 1px solid $c-module-border;
                }
            }
            .total {
                padding-top: 10px;
                margin-top: 10px;
                border-top: 1px solid $c-module-border;
            }
        }
    }
}